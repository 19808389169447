<template>
    <div id="discount-details-target">
        <b-card class="st-section mt-6">
            <div class="st-section-body">
                <div class="discount-section-title">{{ $t("APPLICATION.DISCOUNTS.TITLE") }}</div>
                <div class="discount-help">
                    <p class="discount-help-text">{{ $t("APPLICATION.DISCOUNTS.HELP_TEXT") }}</p>
                </div>
                
                <div>
                    <b-form-select
                        v-model="selectedDiscountId"
                        value-field="id"
                        text-field="name"
                        ref="discount"
                        name="discount"
                        :disabled="viewMode"
                        :options="options"
                        @change="onChange"
                    >
                        <template #first>
                            <b-form-select-option
                                :value="null"
                            >
                                {{ $t("APPLICATION.DISCOUNTS.NO_DISCOUNT") }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </div>

                <div v-if="description" class="discount-description">
                    <p class="discount-description-header">{{ $t("APPLICATION.DISCOUNTS.DESCRIPTION_HEADER") }}</p>
                    <p class="discount-description-text">{{description}}</p>
                    <p class="discount-description-warning">{{ $t("APPLICATION.DISCOUNTS.DESCRIPTION_WARNING") }}</p>
                </div>

            </div>
        </b-card>
    </div>
</template>

<script>

export default {
    name: "ApplicationDiscount",
    props: {
        viewMode: {
            type: Boolean,
            required: false,
        },
        options: {
            type: Array,
            required: false,
        },
        discountDetails: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            selectedDiscountId: null,
        };
    },
    computed: {
        description() {
            const discount = this.options.find(option => option.id === this.selectedDiscountId)
            return discount ? discount.description : '';
        },
    },
    methods: {
        onChange(value) {
            this.$emit("updateSelectedDiscount", value);
        },
    },
    created() {
        if (this.discountDetails?.id) {
            this.selectedDiscountId = this.discountDetails.id;
        }
    },
};
</script>
